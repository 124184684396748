<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button
          _key="btnGroupRentCreate"
          title="Grupo"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          :clicked="create"
        />
      </div>
    </ViewTemplateConfiguration>
    <Modal
      title="Grupo"
      :width="500"
      :height="250"
      v-show="showModal('groupRentCreateUpdate')"
    >
      <GroupRentCreateUpdate />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import GroupRentCreateUpdate from "../../../components/modules/rental/group-rent/GroupRentCreateUpdate.vue";

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "GroupRentalView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal,
    GroupRentCreateUpdate,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/rental/group-rent/get-all",
        urlDeleteAllApi: "/api/v1/rental/group-rent/delete",
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            eventName: "groupRentUpdate",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("groupRentCreateUpdate");
      this.addEvent({ name: "groupRentCreate" });
      this.removeLoading(["btnGroupRentCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "groupRentUpdate") this.openModal("groupRentCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
