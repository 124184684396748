<template>
  <div>
    <Panel :module="panel.module" :title="panel.title" :showFilter="panel.showFilter" :showSearch="panel.showSearch"
      :showButtons="panel.showButtons">
      <div slot="content-main">
        <div>
          <br />
          <b-tabs>
            <b-tab title="Grupo">
              <GroupRent />
            </b-tab>
            <!--  <b-tab title="Kit">
              <KitRent />
            </b-tab> -->
            <b-tab title="Tipo">
              <TypeRent />
            </b-tab>
            <!--  <b-tab title="Tipo de Pagamento">
              <TypePayment />
            </b-tab> -->
          </b-tabs>
        </div>
      </div>
    </Panel>
  </div>
</template>

<script>

import GroupRent from "./GroupRent.vue";
import KitRent from "./KitRent.vue";
import TypeRent from "./TypeRent.vue";
import TypePayment from '../finance/TypePayment.vue';
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";

import { mapMutations } from "vuex";

export default {
  name: "ConfigRentalView",
  components: { Panel, TypeRent, GroupRent, KitRent, TypePayment },
  data() {
    return {
      panel: {
        module: "Locação",
        title: "Configuração",
        showFilter: false,
        showSearch: false,
        showButtons: false,
      },
    };
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
  },
};
</script>
