<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button
          _key="btnKitRentCreate"
          title="Kit"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          :clicked="create"
        />
      </div>
    </ViewTemplateConfiguration>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "KitRentalView",
  components: {
    ViewTemplateConfiguration,
    Button,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/rental/kit-rent/get-all",
        urlDeleteAllApi: "/api/v1/rental/kit-rent/delete",
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            routeName: "kitRentUpdate",
          },
          {
            field: "description",
            title: "Descrição",
            type: "text",
          },
          {
            field: "value",
            title: "Valor",
            type: "currency",
            classCssTitle: "text-center",
            classCssBody: "text-center",
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.$router.push({
        name: "kitRentCreate",
      });
      this.removeLoading(["btnKitRentCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {},
      deep: true,
    },
  },
};
</script>
