<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList">
      <div slot="content-buttons">
        <Button
          _key="btnTypeRentalCreate"
          title="Tipo"
          classIcon="fas fa-plus-circle"
          type="primary"
          size="small"
          :clicked="create"
        />
      </div>
    </ViewTemplateConfiguration>
    <Modal
      title="Tipo"
      :width="500"
      :height="250"
      v-show="showModal('typeRentalCreateUpdate')"
    >
      <TypeRentCreateUpdate />
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import TypeRentCreateUpdate from "../../../components/modules/rental/type-rent/TypeRentCreateUpdate.vue";

import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapGetters, mapMutations } from "vuex";

export default {
  name: "TypeRentalView",
  components: {
    ViewTemplateConfiguration,
    Button,
    Modal,
    TypeRentCreateUpdate,
  },
  data() {
    return {
      templateList: {
        urlGetApi: "/api/v1/rental/type-rent/get-all",
        urlDeleteAllApi: "/api/v1/rental/type-rent/delete",
        headerTable: [
          {
            field: "name",
            title: "Nome",
            type: "link",
            iconSearch: true,
            eventName: "typeRentalUpdate",
          },
          {
            field: "typeName",
            title: "Tipo",
            type: "text",
            iconSearch: false,
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["addEvent", "openModal", "removeLoading"]),
    create() {
      this.openModal("typeRentalCreateUpdate");
      this.addEvent({ name: "typeRentalCreate" });
      this.removeLoading(["btnTypeRentalCreate"]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "typeRentalUpdate") this.openModal("typeRentalCreateUpdate");
      },
      deep: true,
    },
  },
};
</script>
